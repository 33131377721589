import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle } from '../../../../../../config';
import { FaChartBar, FaFilePdf, FaMoneyBill, FaMoneyBillAlt, FaPiggyBank } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { string } from 'yup';
import handleFinanceReportPDF from './handleFinanceReportPDF';
import { AiFillBank } from 'react-icons/ai';

interface ICreateModule {
  lineData: Record<string, any>;
}

export const FinanceReportByProjectModule: React.FC<ICreateModule> = ({ lineData }) => {

  const { user } = useAuth();
  const { addToast } = useToast();

  const callTransfer = async (url) => {
    try {
      await api.get(url)
      addToast({ title: 'Saque realizado com sucesso', type: 'success' })

    }
    catch (err) {
      addToast({ title: 'Erro ao sacar', type: 'error' })
    }
  }


  const gerarRelatorioFinanceiro = async (operation) => {

    addToast({ title: 'Gerando relatório', type: 'info' });

    await downloadit({ url: `/check-saldo/${lineData.url}/${operation}` });


  }

  const gerarRelatorioFinanceiroPDF = async (operation) => {

    addToast({ title: 'Gerando relatório', type: 'info' });

    const project = await api.get(`/projects/${lineData._id}`)

    const response = await api.get(`/check-saldo/${lineData.url}/json`);

    handleFinanceReportPDF({ data: response?.data, project: project?.data })


  }

  const gerarRelatorioFinanceiroComRecebiveis = async () => {

    addToast({ title: 'Gerando relatório com recebíveis', type: 'info' });

    await downloadit({ url: `/check-saldo-com-recebiveis/${lineData.url}` });


  }



  const moduleUpdate: React.FC<ICreateModule> = ({ lineData }): JSX.Element => (
    <><button title="Relatório Financeiro" className="lineIcon" type="button" onClick={() => gerarRelatorioFinanceiro('')}>
      <FaMoneyBill />
    </button>
      <button title="Listar Saques" className="lineIcon" type="button" onClick={() => gerarRelatorioFinanceiro('saque')}>
        <FaMoneyBillAlt />
      </button>
      <button title="Lista de recebíveis" className="lineIcon" type="button" onClick={() => gerarRelatorioFinanceiroComRecebiveis()}>
        <FaChartBar />
      </button>
      <button title="Relatório Financeiro em PDF com resumo" className="lineIcon" type="button" onClick={() => gerarRelatorioFinanceiroPDF('')}>
        <FaFilePdf />
      </button>
      {user?.email === 'guilherme@encontrodigital.com.br' && lineData?.new_finance === 'yes' ? <button title="Realizar Saque" onClick={() => callTransfer(`/make-marketplace-transfer/${lineData?._id}`)} className="lineIcon" type="button"  >
        <AiFillBank /> </button> : <></>}

    </>
  );

  return moduleUpdate({ lineData });
}
